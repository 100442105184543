import React from "react"
import { Button } from "@mui/material"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import LocalPhoneIcon from "@mui/icons-material/LocalPhone"
import EmailIcon from "@mui/icons-material/Email"
import aboutImage from "../../images/tajmahal-buffet-grantham-about.jpg"
import { OurLocationWrapper } from "./AboutPage.styles"

const OurLocation = () => {
  return (
    <OurLocationWrapper>
      <div className="our-location">
        <h2>Our Location</h2>
        <h3>Where to find us</h3>
        <p>
          "Taj Mahal Buffet Longue", situated at the heart of Grantham Town aims
          to comfort the heart and mind of guests offering fine, up-market
          Chinese, Nepalese and Indian cuisines set in a naturally comfortable
          and modern interior with soft lightening as well as warm ambiance
          adoring the connection between the meaning of life and food. Our
          experienced and creative chefs are masters at presenting a combination
          of traditional flavors with an innovative twist. The foods are
          faithful to their origins but yet modern and beautiful in its style of
          presentation.
        </p>
        <div className="buttons">
          <Button
            className="button location"
            href="https://www.google.com/maps/place/The+George+Shopping+Centre/@52.9125065,-0.64709,17z/data=!3m2!4b1!5s0x48783054f8937c6b:0x504e3796c01d703!4m5!3m4!1s0x4878310d6dd83195:0x92f27a6d58e39e6!8m2!3d52.9125066!4d-0.6426053"
            component="a"
            target="_blank"
            rel="no-referrer no-opener"
          >
            <LocationOnIcon />
            <p>14 The George Shopping Centre, High St | Grantham | NG31 6LH</p>
          </Button>
          <Button
            className="button phone-number"
            href="tel: 01476 859955"
            component="a"
          >
            <LocalPhoneIcon /> <p>01476 859955</p>
          </Button>
          <Button
            className="button email"
            href="mailto: info@granthamtajmahal.co.uk"
            component="a"
          >
            <EmailIcon />
            <p>info@granthamtajmahal.co.uk</p>
          </Button>
        </div>
      </div>
      <div className="our-location-image">
        <img src={aboutImage} />
      </div>
    </OurLocationWrapper>
  )
}

export default OurLocation
