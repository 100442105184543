import { Button } from "@mui/material"
import React from "react"
import ContactusForm from "../Forms/ContactusForm"
import { ContactusWrapper } from "./AboutPage.styles"
import aboutImage from "../../images/tajmahal-buffet-grantham-about.jpg"

const Contactus = () => {
  return (
    <ContactusWrapper>
      <div className="contactus-content">
        <h2>Get in touch</h2>
        <h3> Contact us</h3>
        <p>
          For general enquiries please email:{" "}
          <Button
            className="button email"
            href="mailto: info@granthamtajmahal.co.uk"
            component="a"
          >
            info@granthamtajmahal.co.uk
          </Button>
        </p>
      </div>
      <div className="contactus-form">
        <p>
          Do you have any questions? Do not hesitate to contact us, and we will
          try to accommodate you.
        </p>
        <ContactusForm />
      </div>
    </ContactusWrapper>
  )
}

export default Contactus
