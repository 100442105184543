import styled from "styled-components"
import { colors } from "../../colors.css"

export const Wrapper = styled.div`
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  color: ${colors.active};
  font-size: 1rem;
  
  
  max-width: 1180px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 0 20px;
  &.hs-fieldtype-time,
     {
      &:focus-within {
        > label {
          color: white;
          background-color: red;
        }
      }
      > label {
        background-color: white;
        color: ${colors.active};
        font-family: "PeachExquisiteOpti", Helvetica;
        margin-bottom: -10px;
        font-size: 20px;
        top: 12px;
        position: relative;
        left: 9px;
        padding: 0 5px;
        z-index: 1;
      }
`

export const StyledHubspotForm = styled.div`
  .hs-form fieldset {
    max-width: none;
  }
  .legal-consent-container .hs-form-booleancheckbox-display > span {
    padding-left: 40px;
    margin-left: 0;
  }
  .field {
    // Text box
    &.hs-fieldtype-text,
    &.hs-fieldtype-textarea,
    &.hs-fieldtype-phonenumber,
    &.hs-fieldtype-number,
    &.hs-fieldtype-date,
    &.hs-fieldtype-file,
    &.hs-fieldtype-select {
      &:focus-within {
        > label {
          color: white;
          background-color: red;
        }
      }
      > label {
        background-color: white;
        color: ${colors.active};
        font-family: "PeachExquisiteOpti", Helvetica;
        margin-bottom: -10px;
        font-size: 20px;
        top: 12px;
        position: relative;
        left: 9px;
        padding: 0 5px;
        z-index: 1;
      }
      // Inputs
      .hs-input {
        font-family: "Effra Regular";
        border-radius: 4px;
        border: 1px solid lightgray;
        padding: 18.5px 14px;
        font-size: 16px;
        color: ${colors.active};
        line-height: 1.1876em;
        width: 100%;
        &:hover {
          border-color: ${colors.active};
        }
        &:focus {
          outline: none;
          border-color: ${colors.active};
          border-width: 2px;
        }
      }
    }
    // Checkbox/Radio
    &.hs-fieldtype-booleancheckbox,
    &.hs-fieldtype-checkbox,
    &.hs-fieldtype-radio {
      .inputs-list {
        list-style: none;
        padding: 0;
        & li:before {
          content: none; //override rich text classes
        }
      }
      .hs-form-radio {
        padding: 10px 0;
      }
      .hs-input {
        position: relative;
        top: 0px;
        height: 0;
        width: 0 !important; // To rewrite HS styles on different screen sizes
        visibility: hidden;
      }
      .hs-input + span {
        padding-left: 40px;
        position: relative;
      }
      .hs-input + span::before {
        visibility: visible;
        position: absolute;
        top: -9px;
        left: -9px;
        content: "";
        display: block;
        height: 42px;
        width: 42px;
        border-radius: 50%;
      }
      .hs-input + span:hover::before {
        background-color: #0072b00a;
      }
      .hs-input::after {
        display: inline-block;
        position: absolute;
        top: -12px;
        left: -12px;
      }
    }
    // Checkbox
    &.hs-fieldtype-booleancheckbox,
    &.hs-fieldtype-checkbox {
      .hs-input + span::after {
        position: absolute;
        left: 0px;
        top: -1px;
        visibility: visible;
        width: 24px;
        height: 24px;
        content: "";
        -webkit-mask: url("/icons/checkbox_outline.svg") no-repeat center center;
        mask: url("/icons/checkbox_outline.svg") no-repeat center center;
        background-color: ${colors.active};
        // This would be preferable, but I can't get this to work unfortunately.
        // content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#0072b0"><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/></svg>');
      }
      .hs-input:checked + span::after {
        content: "";
        -webkit-mask: url("/icons/checkbox.svg") no-repeat center center;
        mask: url("/icons/checkbox.svg") no-repeat center center;
        // content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#0072b0"><path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg>');
      }
    }
    // Radio
    &.hs-fieldtype-radio {
      .hs-input + span::after {
        position: absolute;
        left: 0;
        top: -1px;
        visibility: visible;
        content: "";
        width: 24px;
        height: 24px;
        -webkit-mask: url("/icons/radio_outline.svg") no-repeat center center;
        mask: url("/icons/radio_outline.svg") no-repeat center center;
        background-color: ${colors.active};
      }
      .hs-input:checked + span::after {
        -webkit-mask: url("/icons/radio.svg") no-repeat center center;
        mask: url("/icons/radio.svg") no-repeat center center;
      }
    }
    // Select
    &.hs-fieldtype-select {
      .input {
        position: relative;
      }
      .input::after {
        content: "▾";
        // font-size: 17px;
        color: ${colors.active};
        right: 1rem;
        top: 1rem;
        position: absolute;
        pointer-events: none;
      }
      .hs-input {
        appearance: none;
      }
    }
  }
  // Date input
  .hs-dateinput {
    position: relative;
  }
  // Date selector
  .hs-datepicker {
    .is-today .pika-button {
      color: ${colors.active};
    }
    .is-selected .pika-button {
      background-color: white;
      color: ${colors.active};
    }
    .pika-button:hover {
      background-color: ${colors.active} !important;
    }
  }
  .form-columns-2 {
    .hs-form-field:first-of-type {
      .input {
        margin-right: 24px;
      }
    }
  }
  // Error
  .hs-error-msgs {
    color: ${colors.active};
    font-size: 14px;
    margin: 5px 0 0;
    padding: 0 15px;
    list-style: none;
  }
  // Submit button
  .hs-submit {
    padding-right: 5px;
    margin-top: 1rem;
  }
  .hs-button {
    font-family: "Effra Regular";
    color: white;
    background-color: red;
    border-radius: 3px;
    border: 0;
    font-size: 16px;
    font-weight: 500;
    box-shadow: 0px 3px 1px -2px #00000033, 0px 2px 2px 0px #00000024,
      0px 1px 5px 0px #0000001f;
    padding: 6px 16px;
    line-height: 1.75;
    min-width: 64px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover {
      box-shadow: 0px 2px 4px -1px #00000033, 0px 4px 5px 0px #00000024,
        0px 1px 10px 0px #0000001f;
      background-color: ${colors.active};
      cursor: pointer;
    }
  }
  // HubSpot logo
  .sproket {
    display: none;
  }
  // Typography
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${colors.active};
  }
`
