import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import styled from "styled-components"
import Layout from "../components/Layout/Layout"
import PageHero from "../components/PageHero/PageHero"
import SEO from "../components/SEO/SEO"

import OurLocation from "../components/AboutPageComponents/OurLocation"
import Contactus from "../components/AboutPageComponents/Contactus"

const Wrapper = styled.div`
  max-width: 1180px;
  margin: 0 auto;
  padding: 20px;
`

const MapWrapper = styled.div`
  iframe {
    width: 100%;
    height: 400px;
    padding: 5px;
    border-radius: 10px;
  }
`

const ContentWrapper = styled.div`
  display: block;

  @media (min-width: 992px) {
    display: flex;
  }
`

const PageContent = styled.article`
  margin-top: 20px;
`
const About = () => {
  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: { eq: 234 }) {
        content
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1920
                  placeholder: TRACED_SVG
                  height: 600
                )
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="About" />
      {data.wpPage.featuredImage ? (
        <PageHero
          img={
            data.wpPage.featuredImage.node.localFile.childImageSharp
              .gatsbyImageData
          }
        />
      ) : null}
      <Wrapper>
        <OurLocation />
        <Contactus />
      </Wrapper>

      <MapWrapper dangerouslySetInnerHTML={{ __html: data.wpPage.content }} />

      {/* {data.wpPage.featuredImage ? (
        <PageHero
          img={
            data.wpPage.featuredImage.node.localFile.childImageSharp
              .gatsbyImageData
          }
        />
      ) : null}
      <Wrapper>
        <div dangerouslySetInnerHTML={{ __html: data.wpPage.content }} />
      </Wrapper> */}
    </Layout>
  )
}

export default About
