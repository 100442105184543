import styled from "styled-components"
export const OurLocationWrapper = styled.div`
  display: flex;
  gap: 10px;
  .our-location,
  .our-location-image {
    flex: 1;
  }
  @media (max-width: 992px) {
    flex-direction: column;
  }
  img {
    height: 600px;
    object-fit: cover;
  }
  .buttons {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    p {
      margin-left: 5px;
      color: inherit;
      margin: 0;
      padding: 0;
      text-transform: Capitalize;
    }
    .button {
      padding-left: 0;
      display: flex;
      align-items: center;
      gap: 5px;
      justify-content: left;
    }
  }
`
export const ContactusWrapper = styled.div`
  display: flex;
  gap: 10px;
  .contactus-content,
  .contactus-form {
    flex: 1 1 0px;
  }
  a {
    padding-left: 0;
    text-transform: lowercase;
  }

  @media (max-width: 992px) {
    flex-direction: column;
  }
`
